<template>
  <div>
    <el-form-item
      label-width="0"
      :prop="username"
      class="input-container pass-top"
      :class="{ focus: focusShow }"
    >
      <div class="input-label input-label-active">{{ tips }}</div>
      <el-input
        clearable
        :type="type"
        class="input-line"
        @blur="usernameChange('focusShow', username)"
        @input="usernameChange('focusShow', username)"
        v-model="ruleForm[username]"
      >
        <slot>
          <el-button
            class="get-yzm"
            slot="append"
            @click="getYzm"
            v-if="yzmDEtail"
            :disabled="yzmDisabled1"
          >
            <!-- -->
            <span v-show="codeShow">{{ yzmDEtail.detail }}</span>
            <span v-show="!codeShow" v-html="count"></span>
          </el-button>
        </slot>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
import { sendCode, Login } from "@/api/BBS";
export default {
  name: "loginInput",
  props: {
    ruleForm: {
      //传入from数组
      typeof: Array,
      default() {
        return {
          username: "",
          password: "",
          yzm: "",
        };
      },
    },
    type: {
      typeof: String,
      default() {
        return "number";
      },
    },
    username: {
      typeof: { Array, String },
      default() {
        return "username";
      },
    },
    yzmDEtail: {
      typeof: Object,
      default() {
        return false;
      },
    },
    tips: {
      typeof: String,
      default() {
        return "";
      },
    },
    yzmDisabled: {
      typeof: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      //是否选中
      focusShow: false,
      //验证码是否可点击
      //   yzmDisabled: true,
      //切换验证码、倒计时
      yzmDisabled1: true,
      codeShow: true,
      //倒计时ing
      count: 60,
      //设计倒计时时间
      yzmTime: 3,
    };
  },
  created() {
    if (this.yzmDEtail) {
      this.count = this.yzmDEtail.count;
      this.yzmDisabled1 = this.yzmDisabled;
    }
  },
  methods: {
    usernameChange(name, val) {
      //name：需要上抬文字，val:制定验证字段
      // console.log('触发input验证');
      this.ruleForm[val] != "" ? (this[name] = true) : (this[name] = false);
      // //输入完毕前禁止点击验证码！
      // this.yzmDisabled1 = true
      this.$emit("usernameChange1", "phoneNumber");
      // this.focusShow =!this.focusShow
      //   this.$emit.$refs.ruleForm.validateField("username", (b) => {
      //     //如果b为空 且 usename不为空，则通过验证
      //     if (b === "" && this.ruleForm.username !== "") {
      //       this.yzmDisabled = false;
      //     }
      //   });
    },
    getYzm() {
      if (this.yzmDEtail) {
        // this.yzmDEtail.username 验证此输入框prop 是否检验成功。成功则可点击
        if (this.ruleForm[this.yzmDEtail.username] == "") {
          this.yzmDisabled1 = true;
        }
      }
      sendCode({phoneNumber:this.ruleForm.phoneNumber}).then((res) => {
        this.$emit('msgIdChange',res.data.msgId)
        console.log(res);
      });
      this.codeShow = false;
    },
    timer() {
      let timer = setInterval(() => {
        if (this.count === 0) {
          this.yzmDisabled1 = false;
          this.codeShow = true;
          this.count = this.yzmTime;
          clearInterval(timer);
          return (timer = null);
        }
        this.count--;
      }, 1000);
    },
  },
  updated() {},
  watch: {
    codeShow(old, set) {
      if (set) {
        this.yzmDisabled1 = true;
        this.timer();
      }
    },
    yzmDisabled() {
      this.yzmDisabled1 = this.yzmDisabled;
    },
  },
};
</script>
<style lang="less">
.dialog-box {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  } /* chrome */

  input[type="number"] {
    -moz-appearance: textfield; /* firefox */
  }
}
</style>
<style lang="less" scoped>
.el-form {
  width: 100%;

  margin: 0 auto;
  padding-left: 0px;

  border-radius: 10px;
}
.pass-top {
  /deep/.el-form-item__content,
  el-input__inner {
    margin-top: 10px;
  }
}

.input-container {
  position: relative;
  .input-line {
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 0;
      border-bottom: 1px solid #4ea4dc;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    /deep/input {
      background: transparent;
    }
    /deep/.el-input-group__append {
      border: none;
      .get-yzm {
        border: none;
      }
    }
  }
  .input-label {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0 15px;
    font-size: 16px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #919394;
  }
}
.displayCenter {
  display: flex;
  justify-content: center;
}
.focus {
  .input-label-active {
    top: -25px;
    font-size: 12px;
  }
  .input-line {
    &::after {
      width: 100%;
    }
  }
}
.dialog-box {
  /deep/.el-dialog {
    width: 30%;
  }
  /deep/.el-input {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dcdfe6;
    }
  }
}
.demo-ruleForm {
  margin-top: 15px;
}
.login-tab {
  width: 100%;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  text-align: center;
  .tab-item {
    text-align: center;
    padding: 1rem;
    line-height: 2rem;
    cursor: pointer;
    display: inline-block;
  }
  .active {
    font-weight: 700;
    color: #4ea4dc;
  }
  .tab-active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.25rem;
    background-color: #4ea4dc;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 50%;
    left: 0%;
  }
  .tab-active-line-active {
    left: 50%;
  }
}
</style>