<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      class="dialog-box"
    >
      <div class="login-tab">
        <div
          class="tab-item"
          @click="LoginFun(false)"
          :class="{ active: !linePosition }"
        >
          验证码登录
        </div>
        <div
          class="tab-item"
          @click="LoginFun(true)"
          :class="{ active: linePosition }"
        >
          密码登录
        </div>
        <i
          class="tab-active-line"
          :class="{ 'tab-active-line-active': linePosition }"
        ></i>
      </div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rules"
      >
        <!-- <el-form-item
          label-width="0"
          prop="username"
          class="input-container"
          :class="{ focus: focusShow }"
        >
          <div class="input-label input-label-active">手机号</div>
          <el-input
            clearable
            type="number"
            class="input-line"
            @blur="usernameChange('focusShow', 'username')"
            @input="usernameChange('focusShow', 'username')"
            v-model="ruleForm.username"
          ></el-input>
        </el-form-item> -->
        <login-input
          :ruleForm="ruleForm"
          username="phoneNumber"
          tips="手机号"
          @usernameChange1="usernameChange1"
        ></login-input>
        <login-input
          :ruleForm="ruleForm"
          username="code"
          v-if="!linePosition"
          :yzmDisabled="yzmDisabled"
          :yzmDEtail="yzmDEtail"
          tips="验证码"
          v-on:msgIdChange="msgIdChange"
          @usernameChange1="usernameChange1"
        ></login-input>
        <!-- <el-form-item
          label-width="0"
          class="input-container pass-top"
          prop="yzm"
          v-show="!linePosition"
          :class="{ focus: focusShow2 }"
        >
          <div class="input-label input-label-active">手机验证码</div>
          <el-input
            class="input-line"
            type="number"
            @blur="usernameChange('focusShow2', 'yzm')"
            @input="usernameChange('focusShow2', 'yzm')"
            v-model="ruleForm.yzm"
            @keyup.enter.native="submitForm(ruleForm)"
            ><el-button
              class="get-yzm"
              :disabled="yzmDisabled"
              slot="append"
              @click="getYzm"
            >
              <span v-show="codeShow">获取验证码</span>
              <span v-show="!codeShow">{{ count }}</span>
            </el-button></el-input
          >
        </el-form-item> -->
        <el-form-item
          label-width="0"
          class="input-container pass-top"
          prop="password"
          v-if="linePosition"
          :class="{ focus: focusShow3 }"
        >
          <div class="input-label input-label-active">密码</div>
          <el-input
            class="input-line"
            type="password"
            @blur="usernameChange('focusShow3', 'password')"
            @input="usernameChange('focusShow3', 'password')"
            v-model="ruleForm.password"
            @keyup.enter.native="submitForm(ruleForm)"
          ></el-input>
        </el-form-item>
        <!-- <login-input
          v-show="linePosition"
          :ruleForm="ruleForm"
          username="password"
          tips="密码"
          @usernameChange1="usernameChange1"
        ></login-input> -->
        <div class="register-box">
          <div @click.stop="registerShow">立即注册</div>
        </div>
        <el-form-item label-width="0">
          <div class="displayCenter">
            <el-button
              type="primary"
              :loading="Loginloading"
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
            <!-- <el-button @click="login(true)">重置</el-button> -->
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from "./dialog.vue";
import loginInput from "./loginInput.vue";
import { sendCode, Login } from "@/api/BBS";


export default {
  components: { loginInput, Dialog },
  name: "Login",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // Loginloading:{
    //   type:Boolean,
    //   default(){
    //     return false
    //   }
    // },
    handleClose: {
      typeof: Function,
      default() {
        return () => {
          this.$emit("handleClose");
        };
      },
    },
  },
  data() {
    let usernameRule = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (value !== "") {
        if (!this.usernameTest(value)) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let yzmRule = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else if (value !== "") {
        if (!this.yzmTest(value)) {
          callback(new Error("请输入6位验证码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      linePosition: false,
      inputLabelActive: false,
      focusShow: false,
      focusShow2: false,
      focusShow3: false,
      Loginloading: false,
      msgId: "",
      yzmDEtail: {
        detail: "验证码",
        count: 3,
        username: "phoneNumber",
      },
      //验证码是否可点击
      yzmDisabled: true,
      //切换验证码、倒计时
      codeShow: true,
      //倒计时ing
      count: 3,
      //设计倒计时时间
      yzmTime: 3,
      ruleForm: {
        phoneNumber: "",

        password: "",
        code: "",
      },
      rules: {
        phoneNumber: [{ validator: usernameRule, trigger: ["blur", "change"] }],
        code: [
          {
            validator: yzmRule,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async submitForm(name) {
      try {
        // if (this.ruleForm.username === "" && this.ruleForm.yzm == "") {
        //   this.$message({
        //     message: "请输入完整的手机号或验证码",
        //     type: "warning",
        //   });
        // }

        await this.$refs[name].validate((valid, obj) => {
          if (valid) {
            // this.Loginloading = true

            let loginData = this.ruleForm;
            loginData.msgId = this.msgId;
            if (this.ruleForm.password == "") {
              delete loginData.password;
            }
            console.log(loginData);
            Login(loginData).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.handleClose();
                this.$emit("changeLogin");
                this.$store.commit('setToken',res.data.token)
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          }
        });
      } catch (error) {
        this.$message.error("登录失败");
      }

    },

    LoginFun(name) {
      if (name) {
        //密码登录
        this.linePosition = true;
      } else {
        this.linePosition = false;
      }
    },
    registerShow() {
      this.$emit("registerClick");
    },
    msgIdChange(data){
      this.msgId = data
    },
    usernameChange(name, val) {
      //name：显示字段，val:绑定字段, 输入为空时落下

      this.ruleForm[val] != "" ? (this[name] = true) : (this[name] = false);
      // this.focusShow =!this.focusShow
      this.$refs.ruleForm.validateField("username", (b) => {
        //如果b为空：rule验证通过 且 usename不为空，则通过验证
        if (b === "" && this.ruleForm.username !== "") {
          this.yzmDisabled = false;
        } else {
          this.yzmDisabled = true;
        }
      });
    },
    usernameChange1(val) {
      this.$refs.ruleForm.validateField(val, (b) => {
        if (b === "" && this.ruleForm[val] !== "") {
          //关闭验证码可点击
          this.yzmDisabled = false;
        } else {
          this.yzmDisabled = true;
        }
      });
    },
    getYzm() {
      sendCode(this.ruleForm.username).then((res) => {});
    },
    timer() {
      let timer = setInterval(() => {
        if (this.count === 0) {
          this.yzmDisabled = false;
          this.codeShow = true;
          this.count = this.yzmTime;
          clearInterval(timer);
          return (timer = null);
        }
        this.count--;
      }, 1000);
    },
    usernameTest(value) {
      //检验手机号
      return this.valueTest(
        /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
        value
      );
    },
    yzmTest(value) {
      //检验验证码
      return this.valueTest(/^\d{6}$/, value);
    },
    valueTest(value, input) {
      if (value.test(input)) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    ruleForm() {
      this.ruleForm.username == "" ? (this.yzmDisabled = false) : "";
    },
    codeShow(old, set) {
      if (set) {
        this.yzmDisabled = true;
        this.timer();
      }
    },
  },
};
</script>
<style lang="less">
.dialog-box {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  } /* chrome */

  input[type="number"] {
    -moz-appearance: textfield; /* firefox */
  }
}
</style>
<style lang="less" scoped>
.el-form {
  width: 100%;

  margin: 0 auto;
  padding-left: 0px;

  border-radius: 10px;
}
.pass-top {
  /deep/.el-form-item__content {
    margin-top: 15px;
  }
}

.input-container {
  position: relative;
  .input-line {
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      border-bottom: 1px solid #4ea4dc;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    /deep/input {
      background: transparent;
    }
    /deep/.el-input-group__append {
      border: none;
      .get-yzm {
        border: none;
      }
    }
  }
  .input-label {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0 15px;
    font-size: 16px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #919394;
  }
}
.displayCenter {
  display: flex;
  justify-content: center;
}
.focus {
  .input-label-active {
    top: -25px;
    font-size: 12px;
  }
  .input-line {
    &::after {
      width: 100%;
    }
  }
}
.register-box {
  display: flex;
  justify-content: flex-end;
  color: #4ea4dc;
  padding: 0 0 15px 0;
  font-size: 18px;
  > div {
    cursor: pointer;
  }
}
.dialog-box {
  /deep/.el-dialog {
    width: 30%;
  }
  @media (max-width: 767px) {
    /deep/.el-dialog {
      width: 80%;
      margin: 0 auto;
    }
  }
  /deep/.el-input {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dcdfe6;
    }
  }
}
.demo-ruleForm {
  margin-top: 15px;
}
.login-tab {
  width: 100%;
  font-size: 1.4rem;
  max-width: 580px;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  text-align: center;
  display: flex;
  .tab-item {
    text-align: center;
    padding: 1rem;
    line-height: 2rem;
    cursor: pointer;
    display: inline-block;
    width: 50%;
  }
  .active {
    font-weight: 700;
    color: #4ea4dc;
  }
  .tab-active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.25rem;
    background-color: #4ea4dc;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 50%;
    left: 0%;
  }
  .tab-active-line-active {
    left: 50%;
  }
}
</style>