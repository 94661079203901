<template>
  <div class="avatar">
    <div class="login">
      <div class="avatar-container">
        <div class="block">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <div class="Odetail">
          <div class="Odetail-content">
            <div class="outhor">维超智能</div>
            <div class="commentsTime">深圳市维超智能有限公司</div>
          </div>
        </div>
        <el-badge :value="12" class="item" title="信息中心">
          <i class="el-icon-bell"></i>
        </el-badge>
      </div>
      <div class="data-info">
        <dl class="text-center" title="55">
          <dt><span class="count">55</span></dt>
          <dd class="font">原创</dd>
        </dl>
        <dl
          class="text-center"
          data-report-click='{"mod":"1598321000_002","spm":"1001.2101.3001.4311"}'
          title="30"
        >
          <dt><span class="count">30</span></dt>
          <dd class="font">周排名</dd>
        </dl>
        <dl class="text-center" title="2880">
          <dt><span class="count">30</span></dt>
          <dd class="font">总排名</dd>
        </dl>
        <dl class="text-center" style="min-width: 58px" title="356">
          <dt><span class="count">356</span></dt>
          <dd>积分</dd>
        </dl>
        <dl class="text-center" title="6级,点击查看等级说明">
          <dt><span class="count">6</span></dt>
          <dd>等级</dd>
        </dl>
      </div>
      <div class="line-B"></div>
    </div>
    <div v-if="noLogin" class="no-login">
      <div class="no-login-box">
        <p>您尚未登录哦...</p>
        <div class="no-login-btn">
          <el-button type="primary" @click="loginClick" >登录</el-button>
          <el-button type="success" @click="registerClick">注册</el-button>
        </div>
      </div>
    </div>
    <login
      v-if="loginShow"
      :dialogVisible="loginVisible"
      :handleClose="loginClick"
      v-on:registerClick="registerClick"
      v-on:changeLogin="changeLogin"
    ></login>
    <register
      v-if="registerShow"
      :dialogVisible="registerVisible"
      :handleClose="registerClick"
      v-on:changeLogin="changeLogin"
    ></register>
  </div>
</template>

<script>
import login from "../login.vue";
import register from "../register.vue";

export default {
  components: { login, register },
  name: "avatar",
  data() {
    return {
      circleUrl:
        "http://ww2.sinaimg.cn/large/7a8aed7bjw1ezgal5vpjfj20go0p0adq.jpg",
      login: false,
      loginVisible: false,
      registerVisible: false,
      loginShow: false,
      registerShow: false,
      noLogin: true,
    };
  },
  methods: {
    loginClick() {
//  this.$store.commit('setState','213')
      this.loginVisible = !this.loginVisible;
      this.loginShow = !this.loginShow;  
    },
    registerClick() {
      this.registerVisible = !this.registerVisible;
      this.registerShow = !this.registerShow;
    },
    // dialogVisible() {
    //   this.loginVisible = !this.loginVisible;
    //   this.loginShow = !this.loginShow;
    // },
    changeLogin() {
      this.noLogin = false;
    },
  },
};
</script>

<style lang="less" scoped>

.avatar {
  background: white;
  margin-right: 10px;
  position: relative;
  //   display: flex;
  .login {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .no-login {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(95, 93, 93, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    .no-login-box {
      p {
        padding: 30px 0;
        color: white;
      }
      .no-login-btn {
        display: flex;
      }
    }
  }
  .avatar-container {
    display: flex;
    .item {
      font-size: 20px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .Odetail {
    padding: 10px;
    .commentsTime {
      // padding: 9px 10px ;
      color: rgb(95, 93, 93);
    }
  }
  .data-info {
    display: flex;
    padding: 8px 0;
    .text-center {
      padding: 0 5px;
      cursor: pointer;
      .level {
        width: 100%;
        height: 100%;
      }

      dd {
        padding: 3px 0;
      }
    }
  }
}
@media (max-width: 640px) {
  .avatar {
    margin-right: 0;
  }
}
</style>