<template>
  <div class="BBS">
    <header-img
      :bgImg="require('@/assets/img/headerImg/Banner002187-ban1_wap.jpg')"
    ></header-img>
    <div class="BBSContainer">
      <div class="BBSleft">
        <avatar></avatar>
        <div class="make" @click="toEidtor">
          <el-button type="danger" title="发表贴子" plain>发表文章</el-button>
        </div>
      </div>
      <div class="BBSright">
        <router-view></router-view>
      </div>
    </div>

    <!-- <BBSContainer></BBSContainer> -->
  </div>
</template>

<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
import BBSContainer from "@/components/BBS/BBSContainer.vue";
import Avatar from "../components/CommonConponent/BBSMeta/avatar.vue";
export default {
  components: {
    headerImg,
    BBSContainer,
    Avatar,
  },
  methods:{
    toEidtor(){console.log('123');
      this.$router.push('editor');
    }
  }
};
</script>

<style lang="less" scoped>
.BBS {
  min-height: 400px;
  text-align: left;
  background-color: #fafafa;


  .BBSContainer {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;

    .BBSright,
    .BBSleft {
      margin-top: 15px;
    }
    .BBSleft {
      width: 30%;
    }
    .BBSright {
      width: 80%;
    }
    .make {
      padding: 20px 10px;
    }
  }
    @media (max-width: 767px) {
    .BBSContainer {
      display: flex;
      flex-direction: column;
      margin-top: 1vh;
      .BBSleft {
        width: 90%;
        margin: 0 auto;
      }

      .BBSright {
        width: 100%;
      }
    }
  }
}
</style>