<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      class="dialog-box"
    >
      <div class="login-tab">
        <div class="tab-item active">注册账号</div>

        <i class="tab-active-line"></i>
      </div>
      <el-form
        :model="registerForm"
        ref="registerForm"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rules"
      >
        <login-input
          :ruleForm="registerForm"
          username="username"
          tips="手机号"
          @usernameChange1="usernameChange1"
        ></login-input>
        <login-input
          :ruleForm="registerForm"
          username="yzm"
          v-show="!linePosition"
          :yzmDisabled="yzmDisabled"
          :yzmDEtail="yzmDEtail"
          tips="验证码"
          @usernameChange1="usernameChange1"
        ></login-input>
        <login-input
          type="password"
          :ruleForm="registerForm"
          username="password"
          tips="密码"
          @usernameChange1="usernameChange1"
        ></login-input>
        <login-input
          type="password"
          :ruleForm="registerForm"
          username="AginPassword"
          tips="再次确认密码"
          @usernameChange1="usernameChange1"
        ></login-input>

        <el-form-item label-width="0">
          <div class="displayCenter">
            <el-button type="primary" @click="submitForm('registerForm')"
              >注册</el-button
            >
            <!-- <el-button @click="login(true)">重置</el-button> -->
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from "./dialog.vue";
import loginInput from "./loginInput.vue";
export default {
  components: { loginInput, Dialog },
  name: "Login",
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    handleClose: {
      typeof: Function,
      default() {
        return () => {
          this.$emit("handleClose");
        };
      },
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value==='') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    return {
      linePosition: false,
      inputLabelActive: false,
      focusShow: false,
      focusShow2: false,
      focusShow3: false,
      yzmDEtail: {
        detail: "验证码",
        count: 3,
        username: "username",
      },
      //验证码是否可点击
      yzmDisabled: true,
      //切换验证码、倒计时
      codeShow: true,
      //倒计时ing
      count: 3,
      //设计倒计时时间
      yzmTime: 3,
      registerForm: {
        username: "",
        AginPassword: "",
        password: "",
        yzm: "",
      },
      rules: {
        username: [
          {
            require: true,
            message: "请输入正确的手机号",
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
            message: "请输入正确手机号",
            trigger: ["blur", "change"],
          },
        ],
        yzm: [
          {
            require: true,
            message: "请输入4位验证码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^\d{4}$/,
            message: "请输入四位验证码",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            require: true,
            message: "请输入长度在6~18的密码",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[a-zA-Z]\w{5,17}$/,
            message: "以字母开头，长度在6~18之间，只能包含字母、数字和下划线",
            trigger: ["blur", "change"],
          },
        ],
        AginPassword: [
          {
            require: true,
            message: "请输入长度在6~18的密码",
            trigger: ["blur", "change"],
          },
          {
            validator: validatePass,
            trigger: ["blur",],
          },
        ],
      },
    };
  },
  methods: {
    async submitForm(name) {
      this.$refs[name].validate((valid) => {
        console.log(valid);
        if (valid) {
          this.$message.success("登录成功");
          this.handleClose();
        }
      });
      // try {
      //   await this.$refs.ruleForm.validate();
      //   let res = await this.$axios.post(`/login`, {
      //     username: this.ruleForm.username,
      //     password: this.ruleForm.password,
      //   });
      //   this.$message.success("登录成功");
      //   console.log(res.data);
      //   localStorage.setItem("token", res.data.data.token);
      //   localStorage.setItem("user", JSON.stringify(res.data.data.user));
      //   this.$router.push("home/list");
      // } catch (error) {
      //   this.$message.error("登录失败");
      // }
    },

    usernameChange(name, val) {
      //name：显示字段，val:绑定字段, 输入为空时落下

      this.registerForm[val] != "" ? (this[name] = true) : (this[name] = false);
      // this.focusShow =!this.focusShow
      this.$refs.registerForm.validateField("username", (b) => {
        //如果b为空：rule验证通过 且 usename不为空，则通过验证
        if (b === "" && this.registerForm.username !== "") {
          this.yzmDisabled = false;
        } else {
          this.yzmDisabled = true;
        }
      });
    },
    usernameChange1(val) {
      this.$refs.registerForm.validateField(val, (b) => {
        if (b === "" && this.registerForm[val] !== "") {
          //关闭验证码可点击
          this.yzmDisabled = false;
        } else {
          this.yzmDisabled = true;
        }
      });
    },
    getYzm() {
      this.codeShow = false;
    },
    timer() {
      let timer = setInterval(() => {
        if (this.count === 0) {
          this.yzmDisabled = false;
          this.codeShow = true;
          this.count = this.yzmTime;
          clearInterval(timer);
          return (timer = null);
        }
        this.count--;
      }, 1000);
    },
  },

  watch: {
    registerForm() {
      this.registerForm.username == "" ? (this.yzmDisabled = false) : "";
    },
    codeShow(old, set) {
      if (set) {
        this.yzmDisabled = true;
        this.timer();
      }
    },
  },
};
</script>
<style lang="less">
.dialog-box {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  } /* chrome */

  input[type="number"] {
    -moz-appearance: textfield; /* firefox */
  }
}
</style>
<style lang="less" scoped>
.el-form {
  width: 100%;

  margin: 0 auto;
  padding-left: 0px;

  border-radius: 10px;
}
.pass-top {
  /deep/.el-form-item__content {
    margin-top: 15px;
  }
}

.input-container {
  position: relative;
  .input-line {
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      border-bottom: 1px solid #4ea4dc;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    /deep/input {
      background: transparent;
    }
    /deep/.el-input-group__append {
      border: none;
      .get-yzm {
        border: none;
      }
    }
  }
  .input-label {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0 15px;
    font-size: 16px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #919394;
  }
}
.displayCenter {
  display: flex;
  justify-content: center;
}
.focus {
  .input-label-active {
    top: -25px;
    font-size: 12px;
  }
  .input-line {
    &::after {
      width: 100%;
    }
  }
}
.register-box {
  display: flex;
  justify-content: flex-end;
  color: #4ea4dc;
  padding: 0 0 15px 0;
  font-size: 18px;
  > div {
    cursor: pointer;
  }
}
.dialog-box {
  /deep/.el-dialog {
    width: 30%;
  }
    @media (max-width:767px) {
    /deep/.el-dialog{
      width: 80%;
      margin: 0 auto;
    }
  }
  /deep/.el-input {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dcdfe6;
    }
  }
}
.demo-ruleForm {
  margin-top: 15px;
}
.login-tab {
  width: 100%;
  font-size: 1.4rem;
  max-width: 580px;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tab-item {
    text-align: center;
    padding: 1rem;
    line-height: 2rem;
    cursor: pointer;
    display: inline-block;
    width: 50%;
  }
  .active {
    font-weight: 700;
    color: #4ea4dc;
  }
  .tab-active-line {
    height: 0.25rem;
    background-color: #4ea4dc;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 50%;
  }
}
</style>