<template>
  
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      class="dialog-box"    
    >
    <slot></slot>
    </el-dialog>
  
</template>

<script>
export default {
    name:'dialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    handleClose: {
      typeof: Function,
      default() {
        return () => {
            this.$emit('handleClose');
          this.dialogVisible = false;
        };
      },
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-box {
  /deep/.el-dialog {
    // width: 30%;
  }
  /deep/.el-input {
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #dcdfe6;
    }
  }
}
</style>